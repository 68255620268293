<aim-checkbox-v2 *ngIf="multiple" [checked]="selected"
                 [disabled]="disabled" [indeterminate]="indeterminate" class="aim-option__checkbox">
</aim-checkbox-v2>
<ng-content select="aim-icon"/>
<!--v15에서는 'text' 템플릿 변수를 통해 선택된 옵션 라벨을 획득하여 제거X.-->
<div #text class="aim-option__primary-text"><ng-content/></div>
<!-- See a11y notes inside optgroup.ts for context behind this element. -->
<span *ngIf="group && group._inert" class="cdk-visually-hidden">({{ group.label }})</span>
<div [matRippleDisabled]="disabled || disableRipple" [matRippleTrigger]="_getHostElement()" class="aim-option--ripple"
     mat-ripple></div>
