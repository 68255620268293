import { AimCheckboxV2Component } from '@aimmo/design-system/aim-checkbox-v2/src';
import { CommonModule, NgIf } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatRippleModule } from '@angular/material/core';
import { AimAllSelectOptionComponent } from './aim-all-select-option.component';
import { AimOptgroupComponent } from './aim-optgroup.component';
import { AimOptionComponent } from './aim-option.component';


@NgModule({
  declarations: [AimOptionComponent, AimOptgroupComponent, AimAllSelectOptionComponent],
  imports: [
    CommonModule,
    MatRippleModule,
    AimCheckboxV2Component,
    NgIf,
    AimCheckboxV2Component,
    MatRippleModule,
  ],
  exports: [
    AimOptionComponent,
    AimOptgroupComponent,
    AimAllSelectOptionComponent,
  ]
})
export class AimOptionModule {
}
