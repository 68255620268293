import { AimSelectContext } from '@aimmo/design-system/aim-select/model';
import { InjectionToken } from '@angular/core';
import { MatOptionParentComponent } from '@angular/material/core';

export type  AimOptionType = {
  readonly label: string;
  readonly value: string;
  readonly disabled?: boolean | undefined | null;
};

export type  AimOptGroupType = {
  readonly category: string;
  readonly disabled?: boolean | undefined | null;
  readonly options: AimOptionType[];
};

export enum AimOptionSize {
  small = 'small',
  medium = 'medium',
  large = 'large'
}

export const AimOptionParentContext = {
  ...AimSelectContext,
} as const;

export type  AimOptionParentContextType = typeof AimOptionParentContext[keyof typeof AimOptionParentContext];

export interface AimOptionParentComponent extends MatOptionParentComponent {
  readonly context?: AimOptionParentContextType;
}

export const AIM_OPTION_PARENT_COMPONENT = new InjectionToken<AimOptionParentComponent>(
  'AIM_OPTION_PARENT_COMPONENT',
);
