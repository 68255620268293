import { Component, Inject, InjectionToken, Input, Optional } from '@angular/core';
import { MatOptgroup } from '@angular/material/core';
import { AIM_OPTION_PARENT_COMPONENT, AimOptionParentComponent, AimOptionParentContext } from '../model';


export const AIM_OPTGROUP = new InjectionToken<AimLightOptgroupComponent>('AimOptgroup');

@Component({
  host: {
    class: 'aim-optgroup',
    '[class.table]': 'isTableContext',
  },
  selector: 'aim-light-optgroup',
  templateUrl: './aim-light-optgroup.component.html',
  styleUrls: ['./aim-light-optgroup.component.scss'],
  providers: [{ provide: AIM_OPTGROUP, useExisting: AimLightOptgroupComponent }],
  standalone: true,
})
export class AimLightOptgroupComponent<T = any> extends MatOptgroup {
  /** Option 그룹에 값을 지정할 수 있도록 추가한 것으로 특정 타입을 제한하지 않음. */
  @Input() public readonly value: T;

  constructor(@Inject(AIM_OPTION_PARENT_COMPONENT) @Optional() public parent?: AimOptionParentComponent) {
    super(parent);
  }

  public get isTableContext(): boolean {
    return this.parent?.context === AimOptionParentContext.table;
  }
}
