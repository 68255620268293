import { InjectionToken } from '@angular/core';

export type OverlayOffset = { x: number, y: number };

export const SelectOptionHeight = {
  table: 24,
  normal: 32,
} as const;

export type SelectOptionHeightType = typeof SelectOptionHeight[keyof typeof SelectOptionHeight];

export enum AimSelectFieldWidth {
  fill = '100%',
  small = '180px',
  large = '260px',
}

export const AimSelectFieldSize = {
  fill: 'fill',
  small: 'small',
  large: 'large',
} as const;

export type  AimSelectFieldSizeType = typeof AimSelectFieldSize[keyof typeof AimSelectFieldSize];

export const AimSelectContext = {
  normal: 'normal',
  table: 'table',
} as const;

export type  AimSelectContextType = typeof AimSelectContext[keyof typeof AimSelectContext];

export interface AimSelectContext {
  readonly type: AimSelectContextType;
}

export const AIM_SELECT_CONTEXT = new InjectionToken<AimSelectContext>('AIM_SELECT_CONTEXT');
