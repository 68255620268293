import { coerceNumberProperty } from '@angular/cdk/coercion';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Inject,
  Input,
  Optional,
  Output
} from '@angular/core';
import { MatOption } from '@angular/material/core';
import { isNil } from 'lodash-es';
import { AimOptgroupComponent } from './aim-optgroup.component';
import { AIM_OPTION_PARENT_COMPONENT, AimOptionParentComponent, AimOptionParentContext, AimOptionSize } from '../model';
import { AIM_OPTGROUP } from '@aimmo/design-system/aim-light-option/src';

let uniqueIdCounter = 0;

export class AimOptionSelectionChange<T = any> {
  constructor(
    public source: AimOptionComponent<T>,
    public isUserInput = false) {
  }
}

@Component({
  host: {
    class: 'aim-option',
    '[class.table]': 'isTableContext'
  },
  selector: 'aim-option',
  templateUrl: './aim-option.component.html',
  styleUrls: ['./aim-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AimOptionComponent<T = any> extends MatOption<T> {
  @Output() public override readonly onSelectionChange = new EventEmitter<AimOptionSelectionChange<T>>();
  @Input() public override id = `aim-option-${uniqueIdCounter++}`;
  @HostBinding('class') @Input() public size: AimOptionSize = AimOptionSize.medium;
  private tabindex = 0;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    protected changeDetectorRef: ChangeDetectorRef,
    @Optional() @Inject(AIM_OPTION_PARENT_COMPONENT) public parent: AimOptionParentComponent,
    @Optional() @Inject(AIM_OPTGROUP) public override group: AimOptgroupComponent,
  ) {
    super(elementRef, changeDetectorRef, parent, group);
  }

  @Input()
  public get tabIndex(): number {
    return this.disabled ? -1 : this.tabindex;
  }

  public set tabIndex(value: number) {
    this.tabindex = !isNil(value) ? coerceNumberProperty(value) : 0;
  }

  /** aim-all-select-option 확장을 위한 스케폴딩. */
  public get indeterminate(): boolean {
    return false;
  }

  public get isTableContext(): boolean {
    return this.parent?.context === AimOptionParentContext.table;
  }

  public override _getTabIndex(): string {
    return `${this.tabIndex}`;
  }
}
