import { AIM_OPTGROUP } from '@aimmo/design-system/aim-light-option/src';
import { Component, Inject, Input, Optional } from '@angular/core';
import { MatOptgroup } from '@angular/material/core';
import { AIM_OPTION_PARENT_COMPONENT, AimOptionParentComponent, AimOptionParentContext } from '../model';

let uniqueOptgroupIdCounter = 0;

@Component({
  host: {
    class: 'aim-optgroup',
    '[class.table]': 'isTableContext',
  },
  selector: 'aim-optgroup',
  templateUrl: './aim-optgroup.component.html',
  styleUrls: ['./aim-optgroup.component.scss'],
  providers: [{ provide: AIM_OPTGROUP, useExisting: AimOptgroupComponent }],
})
export class AimOptgroupComponent<T = any> extends MatOptgroup {
  /** Option 그룹에 값을 지정할 수 있도록 추가한 것으로 특정 타입을 제한하지 않음. */
  @Input() public readonly value: T;
  // tslint:disable-next-line:variable-name
  public override _labelId = `aim-optgroup-label-${uniqueOptgroupIdCounter++}`;

  constructor(@Inject(AIM_OPTION_PARENT_COMPONENT) @Optional() public parent?: AimOptionParentComponent) {
    super(parent);
  }

  public get isTableContext(): boolean {
    return this.parent?.context === AimOptionParentContext.table;
  }
}
